import webImg from './newProjectImgs/website.png'
import bowlImg from './newProjectImgs/bowling.png'
import umbrellaImg from './newProjectImgs/umbrella.png'
import aslImg from './newProjectImgs/asl.png'
import fitnessImg from './newProjectImgs/fitness.png'
import mentalWellnessImg from './newProjectImgs/mentalWellness.png'
import roboticArmImg from './newProjectImgs/roboticArm.png'
import dentalHygieneImg from './newProjectImgs/dentalHygiene.png'
import adaptiveClothingImg from './newProjectImgs/clothing.png'


const NewProjectInfo = [
    {
        teamName: 'Adaptive Bowling',
        shortDesc: 'A dynamic bowling ramp for wheelchair users',
        longDesc: 'Lorem Ipsum Lorem Ipsum',
        projImg: bowlImg,
    },
    {
        teamName: 'Adaptive Umbrella',
        shortDesc: 'A hands-free umbrella compatible with crutches',
        longDesc: 'Lorem Ipsum Lorem Ipsum ',
        projImg: umbrellaImg,
    },
    {
        teamName: 'ASL Computer Vision',
        shortDesc: 'An app that translates ASL images and videos into English',
        longDesc: 'Lorem Ipsum Lorem Ipsum',
        projImg: aslImg,
    },
    // {
    //     teamName: 'Cooling Project',
    //     shortDesc: 'Lorem Ipsum',
    //     longDesc: 'Lorem Ipsum Lorem Ipsum',
    //     projImg: webImg,
    // },
    {
        teamName: 'Dental Hygiene',
        shortDesc: 'A dental hygiene precision aide for users with mobility disabilities',
        longDesc: 'Lorem Ipsum Lorem Ipsum',
        projImg: dentalHygieneImg,
    },
    {
        teamName: 'Adaptive Clothing',
        shortDesc: 'Clothing that users with mobility disabilities can independently maneuver',
        longDesc: 'Lorem Ipsum Lorem Ipsum',
        projImg: adaptiveClothingImg,
    },
    {
        teamName: 'Fitness App',
        shortDesc: 'A fitness-based augemented reality game geared towards children with ASD',
        longDesc: 'Lorem Ipsum Lorem Ipsum',
        projImg: fitnessImg,
    },
    {
        teamName: 'Mental Wellness App',
        shortDesc: 'A mental wellness app that tracks user input and outputs Ann Arbor-based recommendations',
        longDesc: 'Lorem Ipsum Lorem Ipsum',
        projImg: mentalWellnessImg,
    },
    {
        teamName: 'Robotic Arm',
        shortDesc: 'A robotic arm for use with a wheelchair to help with extended mobility',
        longDesc: 'Lorem Ipsum Lorem Ipsum',
        projImg: roboticArmImg,
    },
]

export default NewProjectInfo;