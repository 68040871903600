import React from 'react';
import './NewProjects.css';
import NewProjectInfo from './NewProjectInfo';
import newProjectsCircle from './newProjectsCircle.svg';
import newProjectInfo from './NewProjectInfo';

function NewProjects() {
  const projectInfo = {
    title: '2025 Projects!',
    desc: 'Our projects will be announced soon! Excited to share who we’re working with and what we’re doing this year.',

    // Replace with new project info later and uncomment return block

    // projects: newProjectInfo,
    // uncomment line above when new projects are figured out!!!
  };
  return (
    <div className="section">
      <div className="new-project-container px-3 py-5">
        <div className="new-project-circle">
          <img src={newProjectsCircle} alt="New project circle" width="100%" />
        </div>
        <div className="new-project-header">
          <h3>{projectInfo.title}</h3>
          <h4>{projectInfo.desc}</h4>
        </div>

        {
          <div className="new-project-body">
            {projectInfo.projects &&
              projectInfo.projects.map((project, idx) => (
                <div key={idx} className="new-project-row">
                  <div className="d-md-flex">
                    <div className="new-project-content">
                      <h5>{project.teamName}</h5>
                      <p>{project.shortDesc}</p>
                      {/* <button className="new-project-card-readmore">
                        <a href={project.link} target="_blank" rel="noopener noreferrer">Learn More</a>
                      </button> */}
                    </div>
                    <div className="new-project-icon">
                      <img src={project.projImg} alt="p1" width="100%" />
                    </div>

                  </div>
                </div>
              ))}
          </div>
        }
      </div>
    </div>
  );
}

export default NewProjects;
