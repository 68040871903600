const AboutSquareInfo = [
  {
    sectionTitle: 'Core Officers',
    sectionDesc:
      'Meet our core team of innovators, who make MedLaunch possible.',
    sectionColor: '#E33B2D',
    sectionElts: [
      {
        name: 'Parth Patel',
        position: 'President',
        img: './officer-photos/2024/ct/parth-1-alt.JPG',
        modalImg: './officer-photos/2024/ct/parth-2.jpg',
        linkedinLink: '/',
        email: 'partpat@umich.edu',
        description: 'Parth is a senior studying Computer Science and is the President of MedLaunch Core Team. He is from Canton, Michigan. His favorite animal is an orca!'
      },
      {
        name: 'Shruti Subramanian',
        position: 'Vice President',
        img: './officer-photos/2024/ct/shruti-1.jpeg',
        modalImg: './officer-photos/2024/ct/shruti-2.jpg',
        linkedinLink: '/',
        email: 'subs@umich.edu',
        description: "Shruti is senior studying Neuroscience with a minor in Business and is hoping to work in the life sciences and medical sectors to positively impact those with a variety of health conditions. In her free time, Shruti can be found running or hiking, reading a book, or hanging out with friends."
      },
      {
        name: 'Hane Eun',
        position: 'Community Outreach',
        img: './officer-photos/2024/ct/hane-1.jpg',
        modalImg: './officer-photos/2024/ct/hane-2.jpeg',
        linkedinLink: '/',
        email: 'haneeun@umich.edu',
        description: "Hane is a junior studying Biology, Health, and Society and is the Community Outreach Chair for MedLaunch Core Team. He is from Troy, Michigan. He once solved a rubik's cube in 22 seconds!"
      },
      {
        name: 'Arsal Naeem',
        position: 'Professional Development',
        img: './officer-photos/2024/ct/arsal-1.jpeg',
        modalImg: './officer-photos/2024/ct/arsal-1.jpeg', // TODO change
        linkedinLink: '/',
        email: 'arsaln@umich.edu',
        description: "Arsal is senior studying MCDB and is the Professional Development Chair for MedLaunch Core Team. He is from Canton, Michigan. He has been skydiving twice, in both New Zealand and Dubai!"
      },
      {
        name: 'Isabel Comfort',
        position: 'Internal',
        img: './officer-photos/2024/ct/isabel-1.jpeg',
        modalImg: './officer-photos/2024/ct/isabel-2.jpeg',
        linkedinLink: '/',
        email: 'icomfort@umich.edu',
        description: "Isabel is junior studying Pharmaceutical Sciences and is the Internal Chair for MedLaunch Core Team. She is from Swampscott, Massachusetts. She loves to cook!"
      },
      {
        name: 'Mason Teachman',
        position: 'Creative',
        img: './officer-photos/2024/ct/mason-1.JPG',
        modalImg: './officer-photos/2024/ct/mason-1.JPG', // TODO change
        linkedinLink: '/',
        email: 'mteach@umich.edu',
        description: ""
      },
      {
        name: 'Caroline Dean',
        position: 'Finance',
        img: './officer-photos/2024/ct/caroline-1.JPEG',
        modalImg: './officer-photos/2024/ct/caroline-2.jpg',
        linkedinLink: '/',
        email: 'csdean@umich.edu',
        description: "Caroline is a junior studying Biomedical Engineering and is the Finance Chair for MedLaunch Core Team. She is from Kingston, Massachusetts. She has ran a marathon!"
      },
      {
        name: 'Derek Fukuda',
        position: 'Logistics',
        img: './officer-photos/2024/ct/derek-1.jpeg',
        modalImg: './officer-photos/2024/ct/derek-2.jpeg',
        linkedinLink: '/',
        email: 'dfukuda@umich.edu',
        description: "Derek is a senior studying Biomedical Engineering and is the Logistics Chair for MedLaunch Core Team. He is from Granite Bay, California. He love comics/manga. One of his favorite mangas is Mob Psycho 100!"
      },
    ],
  },
  {
    sectionTitle: 'Project Managers',
    sectionDesc:
      'Meet our project managers, who will each lead a Biodesign Challenge team during the 2022 school year.',
    sectionColor: '#1E2C55',
    sectionElts: [
      {
        name: 'Thomas Qian',
        position: 'PolarWear',
        img: './officer-photos/2024/pm/thomas-1.png',
        modalImg: './officer-photos/2024/pm/thomas-2.png',
        linkedinLink: '/',
        email: 'thomasqi@umich.edu',
        description: "Thomas is a senior studying Neuroscience and is the project manager for PolarWear. He is from Canton, Michigan. His favorite Movie is Scott Pilgrim vs. the World."
      },
      {
        name: 'Sheri Balthazar',
        position: 'SyringeSense',
        img: './officer-photos/2024/pm/sheri-1.jpg',
        modalImg: './officer-photos/2024/pm/sheri-2.JPEG',
        linkedinLink: '/',
        email: 'sbalt@umich.edu',
        description: "Sheri is a junior studying Biomedical Engineering and is the project manager for Syringe Sense. She is from St. Petersburg, Florida. She can speak French fluently because she lived in Montreal, Canada from about 5 years!"
      },
      {
        name: 'Abdullah Ahsan',
        position: 'Easy-Jab',
        img: './officer-photos/2024/pm/abdullah-1.jpg',
        modalImg: './officer-photos/2024/pm/abdullah-2.jpeg',
        linkedinLink: '/',
        email: 'abahsan@umich.edu',
        description: "Abdullah is a sophmore studying Movement Science and is the project manager for Easy-Jab. He is from Grand Blanc, Michigan. He love comics/manga. He can clap with one hand!"
      },
      {
        name: 'Emma Kaipainen',
        position: 'Launchers F.C.',
        img: './officer-photos/2024/pm/emma-1.PNG',
        modalImg: './officer-photos/2024/pm/emma-2.png',
        linkedinLink: '/',
        email: 'emkaip@umich.edu',
        description: "Emma is a junior studying Mechanical Engineering and is the project manager for Launchers F.C. She is from Ann Arbor, Michigan. She writes songs! Find her on Spotify:)"
      },
      {
        name: 'Aadi Malhotra',
        position: 'ExplorAble',
        img: './officer-photos/2024/pm/aadi-1.jpg',
        modalImg: './officer-photos/2024/pm/aadi-1.jpg',
        linkedinLink: '/',
        email: 'aadishiv@umich.edu',
        description: ""
      }
    ],
  },
  {
    sectionTitle: 'Faculty Sponsor',
    sectionDesc:
      'Introducing our faculty sponsor, who will provide support for our projects.',
    sectionColor: '#398BCD',
    sectionElts: [
      {
        name: 'Dr. Oluwaferanmi O. Okanlami',
        position: 'Faculty Sponsor',
        img: './officer-photos/okanlami_1.png',
        modalImg: './officer-photos/okanlami_2.png',
        fbLink: 'https://www.facebook.com/feranmi.okanlami',
        linkedinLink: 'https://www.linkedin.com/in/okanlami/',
        email: 'okanlami@med.umich.edu',
        description: '',
      },
    ],
  },
];

export default AboutSquareInfo;