const ProjectInfo = [
  {
    sectionTitle: '2024 Projects',
    teams: [
      {
        teamName: 'Launchers F.C.',
        teamDesc: 'The purpose for this device is to create a removable attachment to sports wheelchairs that allows users to play wheelchair soccer, a sport not existing at UM Adaptive Sports',
        teamImg: './teamImgs/2024/soccer.jpg',
        projImg: './newProjectImgs/website.png',
        link: '',
      },
      {
        teamName: 'PolarWear',
        teamDesc: 'The purpose of this device is to provide a mechanism for people with spinal cord disabilities who cannot produce sweat, to regulate their body temperature during physical activity.',
        teamImg: './teamImgs/2024/coolingvest.jpg',
        projImg: './newProjectImgs/website.png',
        link: '',
      },
      {
        teamName: 'ExplorAble',
        teamDesc: 'This app streamlines the process of finding workout partners for individuals with disabilities who want to stay active and social in their communities. ',
        teamImg: './teamImgs/2024/buddypairing.jpg',
        projImg: './newProjectImgs/website.png',
        link: '',
      },
      {
        teamName: 'SyringeSense',
        teamDesc: 'A device that allows for one to make accurate liquid measurements without the need for visual aid ',
        teamImg: './teamImgs/2024/syringesense.jpg',
        projImg: './newProjectImgs/website.png',
        link: '',
      },
      {
        teamName: 'Easy-Jab',
        teamDesc: 'A device that facilitates the administration of an injectable medication to the back area',
        teamImg: './teamImgs/2024/backinjection.jpg',
        projImg: './newProjectImgs/website.png',
        link: '',
      },
    ]
  },
  {
    sectionTitle: '2023 Projects',
    teams: [
      {
        teamName: 'Heat Waves',
        teamDesc: 'Adaptive hair tying mechanism for people with decreased grip strength to promote independence',
        teamImg: './teamImgs/2023/heatwaves.jpg',
        projImg: './newProjectImgs/website.png',
        link: '',
      },
      {
        teamName: 'Strummability',
        teamDesc: 'An accessible guitar for people with hand and upper body mobility disabilities',
        teamImg: './teamImgs/2023/guitar.jpg',
        projImg: './newProjectImgs/website.png',
        link: '',
      },
      {
        teamName: 'Pill Pall',
        teamDesc: 'Digital pill tracker for patients with memory loss',
        teamImg: './teamImgs/2023/pillpall.jpg',
        projImg: './newProjectImgs/website.png',
        link: '',
      },
      {
        teamName: 'Accuringe',
        teamDesc: 'A device that measures liquids for patients that are blind',
        teamImg: './teamImgs/2023/accuringe.jpg',
        projImg: './newProjectImgs/website.png',
        link: '',
      },
      {
        teamName: 'M-BeWell',
        teamDesc: 'A mental wellness app that tracks user input and outputs Ann Arbor-based recommendations tailored for UMich students',
        teamImg: './teamImgs/2023/bwell.jpg',
        projImg: './newProjectImgs/website.png',
        link: '',
      },
      {
        teamName: 'Adaptabowl',
        teamDesc: 'A dynamic bowling ramp for wheelchair users',
        teamImg: './teamImgs/2023/bowling.jpg',
        projImg: './newProjectImgs/website.png',
        link: '',
      },
      {
        teamName: 'Acccessibrella',
        teamDesc: 'A hands-free umbrella compatible with bilateral forearm crutches',
        teamImg: './teamImgs/2023/umbrella.jpg',
        projImg: './newProjectImgs/website.png',
        link: '',
      },
    ]
  },
  {
    sectionTitle: '2022 Projects',
    teams: [
      {
        teamName: 'Adaptive Bowling',
        teamDesc: 'A dynamic bowling ramp for wheelchair users',
        teamImg: './teamImgs/2022/adaptivebowling.jpg',
        projImg: './teamImgs/2022/bowling.png',
        link: '',
      },
      {
        teamName: 'Adaptive Umbrella',
        teamDesc: 'A hands-free umbrella compatible with crutches',
        teamImg: './teamImgs/2022/adaptiveumbrella.jpg',
        projImg: './teamImgs/2022/umbrella.png',
        link: '',
      },
      {
        teamName: 'ASL Computer Vision',
        teamDesc: 'An app that translates ASL images and videos into English',
        teamImg: './teamImgs/2022/ASLapp.jpg',
        projImg: './teamImgs/2022/asl.png',
        link: '',
      },
      {
        teamName: 'Dental Hygiene',
        teamDesc: 'A dental hygiene precision aide for users with mobility disabilities',
        teamImg: './teamImgs/2022/toothfairies.jpg',
        projImg: './teamImgs/2022/dentalHygiene.png',
        link: '',
      },
      {
        teamName: 'Adaptive Clothing',
        teamDesc: 'Clothing that users with mobility disabilities can independently maneuver',
        teamImg: './teamImgs/2022/fashionforward.jpg',
        projImg: './teamImgs/2022/clothing.png',
        link: '',
      },
      {
        teamName: 'Fitness App',
        teamDesc: 'A fitness-based augemented reality game geared towards children with ASD',
        teamImg: './teamImgs/2022/ARapp.jpg',
        projImg: './teamImgs/2022/fitness.png',
        link: '',
      },
      {
        teamName: 'Mental Wellness App',
        teamDesc: 'A mental wellness app that tracks user input and outputs Ann Arbor-based recommendations',
        teamImg: './teamImgs/2022/mentalwellness.jpg',
        projImg: './teamImgs/2022/mentalWellness.png',
        link: '',
      },
      {
        teamName: 'Robotic Arm',
        teamDesc: 'A robotic arm for use with a wheelchair to help with extended mobility',
        teamImg: './teamImgs/2022/roboreach.jpg',
        projImg: './teamImgs/2022/roboticArm.png',
        link: '',
      },
    ]
  },
  {
    sectionTitle: '2021 Projects',
    teams: [
      {
        teamName: 'Fashion Forward',
        teamDesc: 'A custom-sized, easy-to-wear, and adjustable hooded flannel wrap for a quadriplegic community partner to use independently',
        teamImg: './teamImgs/fashionforward_group.jpg',
        projImg: './teamImgs/fashionforward_logo.png',
        link: 'https://sites.google.com/umich.edu/fashionforward/home',
      },
      {
        teamName: 'Baby Blue Bus',
        teamDesc: 'A “baby bouncer” device that allows parents with cerebral palsy to safely and efficiently move their young children around their homes',
        teamImg: './teamImgs/babybluebus_group.jpg',
        projImg: './teamImgs/babybluebus_logo.png',
        link: 'https://drive.google.com/file/d/1cSoYxnjrQL9i6l0s4009M47FDCzIpTyR/view?usp=sharing',
      },
      {
        teamName: 'FutureG',
        teamDesc: 'A machine learning model with a web-based interface to predict the behavior of a type 1 diabetic community partner\'s blood sugar',
        teamImg: './teamImgs/futureg_group.jpg',
        projImg: './teamImgs/futureg_logo.png',
        link: 'https://drive.google.com/file/d/10bdOuDNHuBYDwFD_3WV61eb5f-PKsj0p/view?usp=sharing',
      },
      {
        teamName: 'DiscoverMe',
        teamDesc: 'A game-based, interactive web app to teach children with physical disabilities, specially focused on muscular dystrophy, about anatomy and autonomy',
        teamImg: './teamImgs/discoverme_group.jpg',
        projImg: './teamImgs/discoverme_logo.png',
        link: 'https://drive.google.com/file/d/1O5xpdiLShSjZezE6ARx0y8_R1T46gLrf/view?usp=sharing',
      },
      {
        teamName: 'KaChair',
        teamDesc: 'An open-sourced, cost-effective alternative for pediatric mobility devices using second-hand strollers and low-cost materials that allows independence for its users',
        teamImg: './teamImgs/kachair_group.jpg',
        projImg: './teamImgs/kachair_logo.png',
        link: 'https://drive.google.com/file/d/1DHd18irmQY3L4zwXuccqIaO8qehyTIvl/view?usp=sharing',
      },
      {
        teamName: 'RoboReach',
        teamDesc: 'A robotic arm built to interface with a community partner’s wheelchair to provide extended mobility',
        teamImg: './teamImgs/roboreach_group.jpg',
        projImg: './teamImgs/roboreach_logo.png',
        link: 'https://drive.google.com/file/d/1tkF2QqS6ySmXM787N1vr-WTr8dsgHz-G/view?usp=sharing',
      },
      {
        teamName: 'Arch4All',
        teamDesc: 'A device for wheelchair archers with limited strength that will securely hold the weight of a compound bow',
        teamImg: './teamImgs/archforall_group.jpg',
        projImg: './teamImgs/archforall_logo.png',
        link: 'https://drive.google.com/file/d/1OJnMWBIGybyQQrk6thxc2ZQPKAuk3__N/view?usp=sharing',
      },
    ]
  },
  {
    sectionTitle: '2020 Projects',
    teams: [
      {
        teamName: 'Periods Made Simple',
        teamDesc: 'Menstrual products for women with limited vaginal sensation',
        teamImg: './teamImgs/pms_img.png',
        projImg: './teamImgs/pms_logo.png',
        link: 'https://drive.google.com/file/d/11twNyOelsc_YAfQXG9UWFj9FdQyaXd0O/view?usp=sharing',
      },
      {
        teamName: 'Racq Pacq',
        teamDesc: 'A tennis racket for individuals with limited hand grip',
        teamImg: './teamImgs/racq_pacq_group.png',
        projImg: './teamImgs/racq_pacq_logo.png',
        // Change to new vid later
        link: 'https://drive.google.com/file/d/1539eZ0Q4hsN--biHOzlf7hg3zMYzYpic/view?usp=sharing',
      },
      {
        teamName: 'Myotera',
        teamDesc: 'Muscle monitoring app for stroke rehabilitation',
        teamImg: './teamImgs/myotera_img.png',
        projImg: './teamImgs/myotera_logo.PNG',
        link: 'https://drive.google.com/file/d/1hb_TSS7nKPViO5i7TO-xwhPHAzIgbz7X/view?usp=sharing',
      },
      {
        teamName: 'VRehab',
        teamDesc:
          'Virtual reality games designed for for stroke rehabilitation',
        teamImg: './teamImgs/myotera_img.png',
        projImg: './teamImgs/vrehab_logo.png',
        link: 'https://drive.google.com/file/d/1bqxNC4EIm1GXmsGMonBKyG8Ap7P75Vmi/view?usp=sharing',
      },
      {
        teamName: 'Cheers',
        teamDesc:
          'Video conferencing digital assistance application for seniors',
        teamImg: './teamImgs/cheers_group.PNG',
        projImg: './teamImgs/cheers_logo.png',
        // Change to new vid later
        link: 'https://drive.google.com/file/d/1i-8dq_Es055YAbDZBqnSavJ1gYkj9V9_/view?usp=sharing',
      },
      {
        teamName: 'Protect Our Smile',
        teamDesc:
          'Clear masks for deaf and hard of hearing individuals using lip reading and facial expressions for communication',
        teamImg: './teamImgs/pms_group.PNG',
        projImg: './teamImgs/pos_logo.png',
        link: 'https://drive.google.com/file/d/1nN0d4_d4qo5upsgeb_5cTSAojXX83CRV/view?usp=sharing',
      },
    ],
  },
  {
    sectionTitle: '2019 Projects',
    teams: [
      {
        teamName: 'Racket Scientists',
        teamDesc:
          'An adaptive tennis racket for wheelchair tennis players with limited wrist mobility',
        teamImg: './teamImgs/racketscientists.png',
        projImg: './teamImgs/racketscientists_icon.png',
        link: 'https://medium.com/medlaunch/building-an-adaptive-tennis-racket-for-weakened-grip-strength-43be3ab46387',
      },
      {
        teamName: 'Mars',
        teamDesc:
          "A smart hybrid cushion for young patients with muscular dystrophy (a second iteration upon Evita's project)",
        teamImg: './teamImgs/Mars.png',
        projImg: './teamImgs/mars_icon.png',
        link: 'https://medium.com/medlaunch/designing-a-customizable-pressurized-wheelchair-seat-cushion-8978b5ac7ce5',
      },
      {
        teamName: 'Paintability',
        teamDesc:
          'A motion-activated paint dispensing machine for people with limited arm mobility',
        teamImg: './teamImgs/Paintability.jpg',
        projImg: './teamImgs/paintability_icon.png',
        link: 'https://medium.com/medlaunch/designing-a-motion-sensor-art-studio-15cdcd4c9f97',
      },
      {
        teamName: 'Innovative Instruments',
        teamDesc:
          'A guitar stand to give C4-quadriplegics increased independence when playing the guitar',
        teamImg: './teamImgs/innovativeinstruments.png',
        projImg: './teamImgs/innovativeinstruments_icon.png',
        link: 'https://medium.com/@medlaunch/the-adjustable-guitar-stand-for-quadriplegics-74ac08ebda4e',
      },
      {
        teamName: 'Hausm8',
        teamDesc: 'A mechanism to help C4-quadriplegics open and close doors',
        teamImg: './teamImgs/hausm8.jpeg',
        projImg: './teamImgs/hausm8_icon.png',
        link: 'https://medium.com/medlaunch/smart-ifying-the-home-of-a-quadriplegic-a01528da2d0f',
      },
      {
        teamName: 'GoodKnight',
        teamDesc:
          'A REM sleep detection device that facilitates lucid dreaming to alleviate nightmares in PTSD patients',
        teamImg: './teamImgs/Goodknight.png',
        projImg: './teamImgs/goodknight_icon.png',
        link: 'https://medium.com/medlaunch/what-it-takes-to-get-a-goodknights-sleep-431fa58a225f',
      },
    ],
  },
  {
    sectionTitle: '2018 Projects',
    teams: [
      {
        teamName: 'Thalamus',
        teamDesc: 'A novel hydration method for C4-quadriplegics',
        teamImg: './posters/thalamus_img.png',
        projImg: './teamImgs/thalamus_icon.png',
        link: 'https://drive.google.com/file/d/11tnUJkD2tHuSwT0HtS9cAjS-xNQaNujU/view?usp=sharing',
      },
      {
        teamName: 'Phoenix',
        teamDesc:
          'An assistive glove to improve fine motor skills in C4-quadriplegics',
        teamImg: './posters/phoenix_img.png',
        projImg: './teamImgs/phoenix_icon.png',
        link: 'https://drive.google.com/file/d/14lAdVRZ6NeKpv3OsLz2NrzxMfSOZTfWj/view?usp=sharing',
      },
      {
        teamName: 'Evita Biotech',
        teamDesc:
          'A smart hybrid cushion for young patients with muscular dystrophy',
        teamImg: './posters/evita_img.png',
        projImg: './teamImgs/ellevate_icon.png',
        link: 'https://drive.google.com/file/d/1_CpkhG5mwOTpUFBYrmPGeZZJGueZo9IZ/view?usp=sharing',
      },
      {
        teamName: 'Alleviaid',
        teamDesc:
          'Adaptive toys and games for children with ambulatory disabilities',
        teamImg: './posters/alleviaid_img.png',
        projImg: './teamImgs/alleviaid_icon.png',
        link: 'https://drive.google.com/file/d/1n5ZtTB_zMxRZd_Av1aQQUsTnaBFIjkJO/view?usp=sharing',
      },
    ],
  },
];

export default ProjectInfo;
