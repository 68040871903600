import React from 'react';
import './About.css';
import aboutSquareInfo from './AboutSquareInfo';
import OfficerSquare from './OfficerSquare';
import Header from '../templates/Header';

function Team() {
  var aboutSquareIdx = 0;

  return (
    <div className="about-container">
      <Header title="Our Leadership Team" />
      <div className="officer-square-container">
        {aboutSquareInfo &&
          aboutSquareInfo.map((section, idx) => (
            <div key={idx} className="section">
              <div className="row">
                <div className="w-100">
                  <h2 className="title">{section.sectionTitle}</h2>
                  <h5 style={{ paddingTop: '1rem', paddingBottom: '2rem' }}>
                    {section.sectionDesc}
                  </h5>
                </div>
                <div className="card-deck w-100">
                  {section.sectionElts &&
                    section.sectionElts.map((square) => {
                      aboutSquareIdx += 1;
                      return (
                        <OfficerSquare
                          key={aboutSquareIdx}
                          modalId={aboutSquareIdx}
                          color={section.sectionColor}
                          {...square}
                        />
                      );
                    })}
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

export default Team;

// import React, { useState } from 'react';
// import './About.css';
// import aboutSquareInfo from './AboutSquareInfo';
// import OfficerSquare from './OfficerSquare';
// import Header from '../templates/Header';

// function Team() {
//   const availableYears = Object.keys(aboutSquareInfo).sort((a, b) => b - a); // Sort descending
//   const [selectedYear, setSelectedYear] = useState(availableYears[1]); // Default past year (second latest)

//   const currentSections = aboutSquareInfo['2024']; // Always show current year first
//   const pastSections = aboutSquareInfo[selectedYear];
//   let aboutSquareIdx = 0;

//   return (
//     <div className="about-container">
//       <Header title="Our Leadership Team" />
//       <div className="officer-square-container">
//         {/* Current Year Display */}
//         {currentSections.map((section, idx) => (
//           <div key={idx} className="section">
//             <div className="row">
//               <div className="w-100">
//                 <h2 className="title">{section.sectionTitle}</h2>
//                 <h5 style={{ paddingTop: '1rem', paddingBottom: '2rem' }}>
//                   {section.sectionDesc}
//                 </h5>
//               </div>
//               <div className="card-deck w-100">
//                 {section.sectionElts.map((square) => {
//                   aboutSquareIdx += 1;
//                   return (
//                     <OfficerSquare
//                       key={aboutSquareIdx}
//                       modalId={aboutSquareIdx}
//                       color={section.sectionColor}
//                       {...square}
//                     />
//                   );
//                 })}
//               </div>
//             </div>
//           </div>
//         ))}

//         {/* Past Officers Section
//         <div className="section pt-5">
//           <div className="row">
//             <div className="w-100">
//               <h2 className="title">The people who did the work before...</h2>
//               <div className="btn-group mb-4">
//                 <button
//                   className="btn btn-secondary btn-lg dropdown-toggle"
//                   type="button"
//                   data-toggle="dropdown"
//                   aria-haspopup="true"
//                   aria-expanded="false"
//                 >
//                   {selectedYear}
//                 </button>
//                 <div className="dropdown-menu">
//                   {availableYears
//                     .filter((year) => year !== '2024')
//                     .map((year, idx) => (
//                       <button
//                         key={idx}
//                         className="dropdown-item"
//                         style={{ fontSize: '1.2rem', lineHeight: '24px' }}
//                         onClick={() => setSelectedYear(year)}
//                       >
//                         {year}
//                       </button>
//                     ))}
//                 </div>
//               </div>
//             </div>
//           </div>

//           {pastSections.map((section, idx) => (
//             <div key={idx} className="section">
//               <div className="row">
//                 <div className="w-100">
//                   <h2 className="title">{section.sectionTitle}</h2>
//                   <h5 style={{ paddingTop: '1rem', paddingBottom: '2rem' }}>
//                     {section.sectionDesc}
//                   </h5>
//                 </div>
//                 <div className="card-deck w-100">
//                   {section.sectionElts.map((square) => {
//                     aboutSquareIdx += 1;
//                     return (
//                       <OfficerSquare
//                         key={aboutSquareIdx}
//                         modalId={aboutSquareIdx}
//                         color={section.sectionColor}
//                         {...square}
//                       />
//                     );
//                   })}
//                 </div>
//               </div>
//             </div>
//           ))}
//         </div> */}
//       </div>
//     </div>
//   );
// }

// export default Team;